import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout';

import Contact from 'components/Contact';

export default function ContactPage(props) {
  let {
    data: { contentfulContactPage },
  } = props;

  return (
    <Layout>
      <Contact page={contentfulContactPage} />
    </Layout>
  );
}

export const query = graphql`
  query {
    contentfulContactPage {
      title
      contacts {
        name
        phone
        email
      }
      contactFormTitle
    }
  }
`;
