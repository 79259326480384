import React from 'react';
import { navigate } from 'gatsby-link';

import {
  Spacing,
  ContactInfo,
  SharedStyles,
  Container,
  Fieldset,
  FieldsGroup,
  FieldInput,
  FieldTextarea,
  FieldSubmit,
} from '@matchbox-mobile/kenra-storybook';

const { StSectionInfo, StTitle } = SharedStyles;

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

export default function Contact({ page }) {
  const [state, setState] = React.useState({});

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    console.log('form is submitted', state, form);
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': 'contact',
        ...state,
      }),
    })
      .then(response => {
        console.log('response', response);
      })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error));
  };

  let { contactFormTitle } = page;
  return (
    <>
      <Spacing removeSpaceBottom>
        <StSectionInfo>
          <Container>
            <StTitle colorBlack styleMedium>
              <h2>{contactFormTitle}</h2>
            </StTitle>
            <p>
              Are you in need of help? We are here to support you on your Kenra
              Professional journey. Please complete the form below and we will
              get back to you as soon as possible. If you prefer to call us,
              please leave us a voicemail at 1-800-428-8073 and will return your
              call.
            </p>
          </Container>
        </StSectionInfo>
      </Spacing>

      <Spacing>
        <Container useMediumWidth>
          {/* <form
            name="contact"
            method="POST"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            action="/submitted"
            onSubmit={handleSubmit}
          >
            <input type="hidden" name="form-name" value="contact" />
            <p hidden>
              <label>
                Don’t fill this out:{' '}
                <input name="bot-field" onChange={handleChange} />
              </label>
            </p>
            <Fieldset legend="Name*">
              <FieldsGroup>
                <FieldInput
                  type="text"
                  id="text-id-1"
                  label="First Name"
                  name="firstName"
                  onChange={handleChange}
                />
                <FieldInput
                  type="text"
                  id="text-id-2"
                  label="Last Name"
                  name="lastName"
                  onChange={handleChange}
                />
              </FieldsGroup>
            </Fieldset>
            <Fieldset legend="Email*">
              <FieldInput
                type="email"
                id="email-id-1"
                label="Your email address is secure and is not shared with any third parties. Privacy Policy"
                name="email"
                onChange={handleChange}
              />
            </Fieldset>
            <Fieldset legend="Phone*">
              <FieldsGroup widthAuto>
                <FieldInput
                  type="text"
                  id="num-id-1"
                  label="(###)"
                  size={10}
                  name="phone1"
                  onChange={handleChange}
                />
                <FieldInput
                  type="text"
                  id="num-id-2"
                  label="###"
                  size={10}
                  name="phone2"
                  onChange={handleChange}
                />
                <FieldInput
                  type="text"
                  id="num-id-3"
                  label="####"
                  size={10}
                  name="phone3"
                  onChange={handleChange}
                />
              </FieldsGroup>
            </Fieldset>
            <Fieldset legend="Subject*">
              <FieldInput
                type="text"
                id="subject-id-1"
                name="subject"
                onChange={handleChange}
              />
            </Fieldset>
            <Fieldset legend="Message*">
              <FieldTextarea
                id="message-id-1"
                rows={8}
                name="message"
                onChange={handleChange}
              />
            </Fieldset>
            <FieldSubmit>
              <input value="submit" type="submit" />
            </FieldSubmit>
          </form> */}
          <form
            name="contact"
            method="post"
            action="/submitted"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
          >
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <input type="hidden" name="form-name" value="contact" />
            <p hidden>
              <label>
                Don’t fill this out:{' '}
                <input name="bot-field" onChange={handleChange} />
              </label>
            </p>
            <p>
              <label>
                Your name:
                <br />
                <input type="text" name="name" onChange={handleChange} />
              </label>
            </p>
            <p>
              <label>
                Your email:
                <br />
                <input type="email" name="email" onChange={handleChange} />
              </label>
            </p>
            <p>
              <label>
                Message:
                <br />
                <textarea name="message" onChange={handleChange} />
              </label>
            </p>
            <p>
              <button type="submit">Send</button>
            </p>
          </form>
        </Container>
      </Spacing>
    </>
  );
}
